export const kjokkenListe = [
    "Tømme kjøleskap og fryser for ting som ikke tåler å stå, la det stå på",
    "Best å ikke la det være noe i fryseren pga fare for strømbrudd",
    "Vask kjøleskapet",
    "Tøm brødboks, la lokket stå åpent slik at det ikke blir mugg",
    "Vask stekeovnen",
    "Vurdér om noe bør kastes i matskuffene. Hvis en stund til neste gjest, kast åpne pakninger",
    "Rens filter i oppvaskmaskinen",
    "Vask kaffetrakter og fjern brukt filter",
    "Hvis Hadeland-glass knuses: la stetten ligge igjen i skapet innpakket (knusegaranti)"
]

export const badListe = [
    "Tørk over nede i skuffene",
    "Vask over toalettet og spray med desinfectante i toalettet slik at det ikke utvikles bakterier før det blir vasket",
    "Sjekk sluk på badene",
    "Sett ned varmekabler på laveste nivå"
]

export const bilListe = [
    "Vaske og støvsuge bilen",
    "Fyll full tank (bensin)",
    "Legg igjen nøkkelen",
    "Parker med fronten ut",
    "Koble fra batteriet hvis lenge til neste gjest",
    "La panseret være åpent ved å legge en plastflaske mellom hvis lenge til neste gjest"
]

export const genereltListe = [
    "Vask håndklær og sengetøy hvis tid",
    "Heng opp brukte håndklær og sengetøy slik at det ikke ligger fuktig",
    "Re opp sengene dersom kort tid til neste gjest",
    "Sett alle vinduer på alle bad og i hotellfløyen på gløtt",
    "Tøm søppel",
    "Trekk ut støpsel til varmtvannsberederne på kjøkkenet og det store soverommet hvis lenge til neste gjest",
    "Ta ned vimpelen hvis lenge til neste gjest",
    "Legg uteputer inn i stuen hvis lenge til neste gjest",
    "Børst og tørk av grillen og sett inn i casinettoen",
    "Legg vaskepenger i skrinet: 80 € for 1. etg, eller 130 € hvis  begge etasjer er brukt",
    "Legg igjen oppholdspenger: 200 € per uke",
    "Sett på alarmene oppe og nede",
    "Lås porten",
]