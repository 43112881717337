import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Hjem from "./Pages/Hjem";
import Informasjon from "./Pages/Informasjon";
import Huskeliste from "./Pages/Huskeliste";
import Tips from "./Pages/Tips";
import Layout from "./Pages/Layout";


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Hjem/>}/>
                    <Route path="informasjon" element={<Informasjon/>}/>
                    <Route path="tips" element={<Tips/>}/>
                    <Route path="huskeliste" element={<Huskeliste/>}/>
                    <Route path="*" element={<Hjem/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
